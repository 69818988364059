import { FC, ReactNode } from "react";

import { Accordion } from "./Accordion";
import { GroupByHeader } from "./GroupByHeader";
import clsx from "clsx";

interface Props {
  children: (resource: any) => ReactNode;
  isSelected: (resource: any) => boolean;
}

interface ListViewProps extends Props {
  resources: any[];
}

interface GroupViewProps extends Props {
  groupedResources: Record<string, any>;
}

interface GroupByKeysViewProps extends Props {
  label: string;
  field: string;
  resources: any[];
  components: any[];
}

interface ListWrapperProps {
  children: ReactNode;
}

export const ListWrapper: FC<ListWrapperProps> = ({ children }) => {
  return (
    <div className="w-full relative flex space-x-1 items-start">{children}</div>
  );
};

export const ListView: FC<ListViewProps> = ({
  resources,
  children,
  isSelected,
}) => {
  return (
    <ul className="flex flex-col space-y-1 grow overflow-x-hidden">
      {resources.map((r) => (
        <li
          key={r.id}
          className={clsx("py-2 hover:bg-gray-50 focus:bg-gray-900", {
            "bg-gray-50": isSelected(r),
          })}
        >
          {children(r)}
        </li>
      ))}
    </ul>
  );
};

export const GroupView: FC<GroupViewProps> = ({
  groupedResources,
  ...rest
}) => {
  return (
    <>
      {Object.keys(groupedResources).map((alphabet) => (
        <ListWrapper key={alphabet}>
          <div className="sticky top-0 px-3 pt-2 w-2">
            <h3>{alphabet}</h3>
          </div>
          <ListView resources={groupedResources[alphabet] ?? []} {...rest} />
        </ListWrapper>
      ))}
    </>
  );
};

export const GroupByKeysView: FC<GroupByKeysViewProps> = ({
  label,
  field,
  resources,
  components,
  ...rest
}) => {
  return (
    <>
      {resources.map((r) => (
        <Accordion
          key={r.id}
          defaultOpen={false}
          panelClassName="px-0"
          className="bg-white z-20 w-full md:sticky md:top-0 items-center"
          title={
            <div className="w-full px-4 flex">
              <div className="flex-1">
                <GroupByHeader
                  componentId={r.componentId}
                  componentValue={r.componentValue}
                  components={components}
                />
              </div>
              <span className="ml-2 text-gray-600">({r.count})</span>
            </div>
          }
        >
          <ListView resources={r[field] ?? []} {...rest} />
        </Accordion>
      ))}
    </>
  );
};
