import {
  AllowedContactType,
  AvailableNotificationTypes,
  Pagination,
} from "@amenda-types";
import {
  CONTACT_FRAGMENT,
  USER_FRAGMENT,
} from "@amenda-domains/fragments/users";
import { createJSONStorage, persist } from "zustand/middleware";
import { gql, useMutation } from "urql";
import { isEmpty, uniq } from "lodash";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { create } from "zustand";
import { groupUsersAlphabetically } from "@amenda-components/Contacts/common";
import { immer } from "zustand/middleware/immer";
import { useAppStore } from "@amenda-domains/mutations/app";

interface UserSearchFilters {
  searchTerm?: string;
  groupByComponentIds?: string[];
  contactType?: AllowedContactType;
}

type State = {
  userGroupingComponents: Record<string, any[]>;
  openContactListSlideOver: boolean;
  openCompanySlideOver: boolean;
  openPersonSlideOver: boolean;
  shiftSelectedUsers: number[];
  userSearchFilters: Record<string, UserSearchFilters>;
  userComponentFilters: Record<string, Record<string, any>>;
  openUsersFilterMenu: boolean;
  isSearchingContacts: boolean;
  isFetchingContacts: boolean;
  isFetchingContact: boolean;
  pagination: Pagination;
  users: any[];
  selectedUsers: string[];
  groupedUsers: Record<string, any>;
  modals: Record<string, boolean>;
  currentUser: Record<string, any>;
  selectedUser: Record<string, any>;
  selectedUserId?: string;
  isSubmitting: boolean;
  userForm: Record<string, any>;
  openUsersTableView: boolean;
  addUserSlideOverCb?: (user: any) => void;
};

type SetUsers = Pagination & {
  users: any[];
  autoSelect?: boolean;
  sortBy?: string;
};
type UpdateUsersArgs = {
  users: any[];
  isGrouped?: boolean;
  autoSelect?: boolean;
};
type SetUserComponentFilter = {
  path: string;
  componentId: string;
  filters: Record<string, any>;
};

type ContactsLoaderProps = Pagination & {
  userDetails?: any;
  contactDetails?: any;
  singleContact?: any;
};

type Actions = {
  setUserGroupingComponents: (path: string, components: any[]) => void;
  setUserSearchFilters: (args: {
    path: string;
    key: keyof UserSearchFilters;
    value: any;
  }) => void;
  setShiftSelectedUser: (user: any) => void;
  setContactsLoader: (loading: boolean, props: ContactsLoaderProps) => void;
  setUserComponentFilter: (args: SetUserComponentFilter) => void;
  setOpenUsersFilterMenu: (isOpen: boolean) => void;
  setIsSearchingContacts: (isSearching: boolean) => void;
  clearSelectedUsers: () => void;
  clearUserSearchFilters: (path: string) => void;
  toggleSelectedUser: (user: Record<string, any>) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setCurrentUser: (user: Record<string, any>) => void;
  setSelectedUserId: (user: Record<string, any>) => void;
  setSelectedUser: (user: any) => void;
  clearSelectedUser: () => void;
  setUsers: (args: SetUsers) => void;
  updateUsers: (args: UpdateUsersArgs) => void;
  setCreateOfficeUserModal: (isOpen: boolean) => void;
  setOfficeUserModal: (isOpen: boolean) => void;
  setCreateContactModal: (isOpen: boolean) => void;
  setUserForm: (formContact: Record<string, any>) => void;
  updateCurrentUser: (user: Record<string, any>) => void;
  updateUser: (user: Record<string, any>) => void;
  setOpenUsersTableView: (isOpen: boolean) => void;
  setOpenPersonSlideOver: (isOpen: boolean, cb?: (user: any) => void) => void;
  setOpenCompanySlideOver: (isOpen: boolean, cb?: (user: any) => void) => void;
  setOpenContactListSlideOver: (isOpen: boolean) => void;
};

export const updateSelectedItemWithNextItem = <
  T extends {
    id?: string;
    isDeleted?: boolean;
  },
>({
  existingItem,
  updatedItem,
  nextSelectedItem,
}: {
  existingItem?: T;
  updatedItem?: T;
  nextSelectedItem?: T;
}) => {
  if (
    existingItem?.id &&
    updatedItem?.id &&
    existingItem.id === updatedItem.id
  ) {
    return Boolean(updatedItem?.isDeleted) ? nextSelectedItem : updatedItem;
  }
  return existingItem;
};

const updateGroupedUsers = (
  groupedUsers: Record<string, any[]>,
  user: Record<string, any>,
) => {
  let updatedGroupedUsers: Record<string, any[]> = {};

  Object.keys(groupedUsers).forEach((key) => {
    updatedGroupedUsers[key] = groupedUsers[key].map((u) =>
      u.id === user.id ? user : u,
    );
  });
  return updatedGroupedUsers;
};

const persistedStateVersion = 0.3;

export const useUsersStore = create(
  persist(
    immer<State & Actions>((set, get) => ({
      userGroupingComponents: {},
      userSearchFilters: {},
      shiftSelectedUsers: [],
      currentUser: {},
      isEditing: false,
      isSubmitting: false,
      userForm: {},
      pagination: {},
      users: [],
      groupedUsers: {},
      userGroupComponents: [],
      openCompanySlideOver: false,
      openPersonSlideOver: false,
      selectedUsers: [],
      modals: {
        openCreateOfficeUserModal: false,
        openCreateContactModal: false,
        openEditOfficeUserModal: false,
      },
      openUsersTableView: false,
      isSearchingContacts: false,
      selectedGroupingComponent: {},
      selectedUser: {},
      isFetchingContacts: false,
      isFetchingContact: false,
      openUsersFilterMenu: false,
      userComponentFilters: {},
      openContactListSlideOver: false,
      setOpenContactListSlideOver(isOpen) {
        set((state) => {
          state.openContactListSlideOver = isOpen;
        });
      },
      setUserGroupingComponents: (path, components) => {
        set((state) => {
          const groupingComponents = get().userGroupingComponents;

          state.userGroupingComponents = {
            ...groupingComponents,
            [path]: components,
          };
        });
      },
      setUserSearchFilters({ key, value, path }) {
        set((state) => {
          const filters = get().userSearchFilters[path] || {};

          state.users = [];
          state.groupedUsers = {};
          state.selectedUserId = undefined;
          state.userSearchFilters[path] = {
            ...filters,
            [key]: value,
          };
        });
      },
      setShiftSelectedUser: (user) =>
        set((state) => {
          const index = get().users.findIndex((u) => u.id === user.id);
          const usersIndex = [...get().shiftSelectedUsers, index];

          if (usersIndex.length > 1) {
            const start = Math.min(...usersIndex);
            const end = Math.max(...usersIndex);

            let selectedUsers = get()
              .users.slice(start, end)
              .map((u) => u.id);
            selectedUsers = uniq([
              ...selectedUsers,
              ...get().selectedUsers,
              user.id,
            ]);

            state.shiftSelectedUsers = [];
            state.selectedUsers = selectedUsers;
          } else {
            state.shiftSelectedUsers = [index];
            state.selectedUsers = [user.id];
          }
        }),
      setContactsLoader: (loading, props) =>
        set((state) => {
          if (props?.singleContact) {
            state.isFetchingContact = loading;
          } else if (!isEmpty(props?.userDetails)) {
            return;
          } else if (!isEmpty(props?.contactDetails)) {
            return;
          } else if (props?.next) {
            state.isFetchingContacts = loading;
          } else {
            state.isSearchingContacts = loading;
          }
        }),
      setUserComponentFilter: ({ path, componentId, filters }) =>
        set((state) => {
          const userComponentFilters = get().userComponentFilters;

          state.userComponentFilters = {
            ...userComponentFilters,
            [path]: {
              ...(userComponentFilters[path] ?? {}),
              [componentId]: filters,
            },
          };
        }),
      setOpenUsersFilterMenu: (isOpen) =>
        set((state) => {
          state.openUsersFilterMenu = isOpen;
        }),
      setSelectedUser: (user) =>
        set((state) => {
          state.selectedUser = user;
        }),
      setIsSearchingContacts: (isSearching) =>
        set((state) => {
          state.isSearchingContacts = isSearching;
        }),
      clearUserSearchFilters: (path) =>
        set((state) => {
          const userGroupingComponents = get().userGroupingComponents;
          const userSearchFilters = get().userSearchFilters;
          const userComponentFilters = get().userComponentFilters;

          state.users = [];
          state.groupedUsers = {};
          state.selectedUserId = undefined;
          state.userSearchFilters = {
            ...userSearchFilters,
            [path]: {},
          };
          state.userGroupingComponents = {
            ...userGroupingComponents,
            [path]: [],
          };
          state.userComponentFilters = {
            ...userComponentFilters,
            [path]: {},
          };
        }),
      setOpenUsersTableView: (isOpen) =>
        set((state) => {
          state.openUsersTableView = isOpen;
        }),
      clearSelectedUsers: () =>
        set((state) => {
          state.selectedUsers = [];
        }),
      toggleSelectedUser: (user) =>
        set((state) => {
          const users = state.selectedUsers;
          const hasUser = users.some((id) => id === user.id);

          if (!hasUser) {
            state.selectedUsers = [...users, user.id];
          } else {
            state.selectedUsers = users.filter((u) => u !== user.id);
          }
        }),
      clearSelectedUser: () =>
        set((state) => {
          state.selectedUserId = undefined;
          state.selectedUser = {};
        }),
      setIsSubmitting: (isSubmitting) =>
        set((state) => {
          state.isSubmitting = isSubmitting;
        }),
      updateCurrentUser: (user) =>
        set((state) => {
          if (state.currentUser.uid === user.uid) {
            state.currentUser = {
              ...state.currentUser,
              ...user,
            };
          }
        }),
      setUserForm: (form) =>
        set((state) => {
          state.userForm = form;
        }),
      setCurrentUser: (user) =>
        set((state) => {
          state.currentUser = user;
        }),
      setOpenCompanySlideOver: (isOpen, cb) => {
        set((state) => {
          state.openCompanySlideOver = isOpen;
          state.addUserSlideOverCb = cb;
        });
      },
      setOpenPersonSlideOver: (isOpen, cb) => {
        set((state) => {
          state.openPersonSlideOver = isOpen;
          state.addUserSlideOverCb = cb;
        });
      },
      setSelectedUserId: (user) =>
        set((state) => {
          state.selectedUserId = user?.id;
        }),
      setOfficeUserModal: (isOpen) =>
        set((state) => {
          state.modals.openEditOfficeUserModal = isOpen;
        }),
      setCreateOfficeUserModal: (isOpen) =>
        set((state) => {
          state.modals.openCreateOfficeUserModal = isOpen;
        }),
      setCreateContactModal(isOpen) {
        set((state) => {
          state.modals.openCreateContactModal = isOpen;
        });
      },
      updateUsers: ({ users, autoSelect, isGrouped }) =>
        set((state) => {
          if (!isGrouped) {
            const { selectedUser, groupedUsers } =
              groupUsersAlphabetically(users);

            if (autoSelect) {
              state.selectedUserId = selectedUser?.id;
            }
            state.users = users;
            state.groupedUsers = groupedUsers;
          } else {
            const selectedUserId = users?.[0]?.users?.[0]?.id;
            const groupedUsers = users.reduce((acc, value) => {
              const key = value.id;
              acc[key] = value.users;
              return acc;
            }, {});

            state.users = users;
            state.groupedUsers = groupedUsers;
            state.selectedUserId = selectedUserId;
          }
        }),
      setUsers: ({ users, autoSelect, sortBy, ...pagination }) =>
        set((state) => {
          const { selectedUser, groupedUsers } = groupUsersAlphabetically(
            users,
            sortBy,
          );

          state.users = users;
          state.groupedUsers = groupedUsers;

          if (Boolean(autoSelect) && isEmpty(get().selectedUserId)) {
            state.selectedUser = {};
            state.selectedUserId = selectedUser?.id;
          }
          state.pagination = pagination;
        }),
      updateUser: (user) =>
        set((state) => {
          if (get().selectedUserId === user.id) {
            state.selectedUser = user;
          }
          state.users = get().users.map((u) => (u.id === user.id ? user : u));
          state.groupedUsers = updateGroupedUsers(get().groupedUsers, user);
        }),
    })),
    {
      name: "users-storage",
      version: persistedStateVersion,
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state) => ({
        openUsersFilterMenu: state.openUsersFilterMenu,
        userSearchFilters: state.userSearchFilters,
        userGroupingComponents: state.userGroupingComponents,
        selectedUsers: state.selectedUsers,
      }),
    },
  ),
);

export const useUserSearchFiltersWithPath = () => {
  const { pathname } = useLocation();
  const userSearchFilters = useUsersStore((state) => state.userSearchFilters);
  const clearUserSearchFilters = useUsersStore(
    (state) => state.clearUserSearchFilters,
  );
  const setUserSearchFilters = useUsersStore(
    (state) => state.setUserSearchFilters,
  );
  const searchFilters = useMemo(
    () => userSearchFilters[pathname] ?? {},
    [userSearchFilters, pathname],
  );
  const userGroupingComponents = useUsersStore(
    (state) => state.userGroupingComponents,
  );
  const groupingComponents = useMemo(
    () => userGroupingComponents[pathname] ?? [],
    [userGroupingComponents, pathname],
  );
  const setUserGroupingComponents = useUsersStore(
    (state) => state.setUserGroupingComponents,
  );
  const componentFilters = useUsersStore((state) => state.userComponentFilters);
  const userComponentFilters = useMemo(
    () => componentFilters[pathname] ?? {},
    [componentFilters, pathname],
  );
  const setComponentFilters = useUsersStore(
    (state) => state.setUserComponentFilter,
  );

  const clearSearchFilters = useCallback(() => {
    clearUserSearchFilters(pathname);
  }, [clearUserSearchFilters, pathname]);

  const setGroupingComponents = useCallback(
    (components: any[]) => {
      setUserGroupingComponents(pathname, components);
    },
    [setUserGroupingComponents, pathname],
  );

  const setUserComponentFilter = useCallback(
    (componentId: string, filters: any) => {
      setComponentFilters({ componentId, filters, path: pathname });
    },
    [setComponentFilters, pathname],
  );

  const setSearchFilters = useCallback(
    (key: keyof UserSearchFilters, value: any) => {
      setUserSearchFilters({ key, value, path: pathname });
    },
    [setUserSearchFilters, pathname],
  );

  return {
    searchFilters,
    groupingComponents,
    userComponentFilters,
    clearSearchFilters,
    setSearchFilters,
    setGroupingComponents,
    setUserComponentFilter,
  };
};

const CREATE_CONTACT = gql`
  ${CONTACT_FRAGMENT}
  mutation CreateContact($input: CreateContactInput!) {
    createContact(input: $input) {
      ...ContactFragment
    }
  }
`;

const UPDATE_CONTACT = gql`
  ${CONTACT_FRAGMENT}
  mutation UpdateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      ...ContactFragment
    }
  }
`;

const UPDATE_USER = gql`
  ${USER_FRAGMENT}
  mutation UpdateUser($input: UserUpdateInput!) {
    updateUser(input: $input) {
      ...UserFragment
    }
  }
`;

const USERS_SYNC = gql`
  mutation SyncUsers {
    syncMsTenantUsers
  }
`;

export const useSyncUsers = () => {
  const showNotification = useAppStore((state) => state.showNotification);
  const [result, callSyncUsers] = useMutation(USERS_SYNC);

  const syncUsers = () => {
    return callSyncUsers().then(({ data, error }) => {
      if (error) {
        showNotification(AvailableNotificationTypes.Error, error.message);
      } else if (data) {
        showNotification(AvailableNotificationTypes.Success, "Sync started");
      }
    });
  };

  return {
    syncUsers,
    loading: result.fetching,
  };
};

export const useCreateContact = () => {
  const showNotification = useAppStore((state) => state.showNotification);
  const [result, callCreateContact] = useMutation(CREATE_CONTACT);

  const createContact = ({
    callback,
    ...variables
  }: {
    input: Record<string, any>;
    callback?: (contact: any) => void;
  }) => {
    return callCreateContact(variables)
      .then(({ data }) => {
        if (data?.createContact) {
          callback?.(data.createContact);
        }
        showNotification(AvailableNotificationTypes.Success, "Contact added!");
        return data?.createContact;
      })
      .catch((error) => {
        showNotification(AvailableNotificationTypes.Error, error.message);
      });
  };

  return {
    createContact,
    loading: result.fetching,
  };
};

export const useUpdateContact = () => {
  const showNotification = useAppStore((state) => state.showNotification);
  const [result, callUpdateContact] = useMutation(UPDATE_CONTACT);
  const updateUserInStore = useUsersStore((state) => state.updateUser);

  const updateContact = (variables: Record<string, any>) => {
    return callUpdateContact(variables)
      .then(({ data }) => {
        if (data?.updateContact) {
          updateUserInStore(data.updateContact);
        }
      })
      .catch((error) => {
        showNotification(AvailableNotificationTypes.Error, error.message);
      });
  };

  return {
    updateContact,
    loading: result.fetching,
  };
};

export const useUpdateUser = () => {
  const showNotification = useAppStore((state) => state.showNotification);
  const [result, callUpdateUser] = useMutation(UPDATE_USER);
  const updateCurrentUser = useUsersStore((state) => state.updateCurrentUser);
  const updateUserInStore = useUsersStore((state) => state.updateUser);

  const updateUser = (variables: Record<string, any>) => {
    return callUpdateUser(variables)
      .then(({ data }) => {
        if (data?.updateUser) {
          updateCurrentUser(data.updateUser);
          updateUserInStore(data.updateUser);
        }
      })
      .catch((error) => {
        showNotification(AvailableNotificationTypes.Error, error.message);
      });
  };

  return {
    updateUser,
    loading: result.fetching,
  };
};

const ACTIVATE_USER = gql`
  mutation ActivateUser($input: UserActivationInput!) {
    activateUser(input: $input) {
      uid
      email
    }
  }
`;

export const useActivateUser = () => {
  const navigate = useNavigate();
  const showNotification = useAppStore((state) => state.showNotification);
  const [result, callActiveUser] = useMutation(ACTIVATE_USER);
  const setCurrentUser = useUsersStore((state) => state.setCurrentUser);

  const activateUser = (variables: Record<string, any>) => {
    return callActiveUser(variables)
      .then(({ data }) => {
        if (data?.activateUser) {
          setCurrentUser(data.activateUser);
          showNotification(
            AvailableNotificationTypes.Success,
            "User activated",
          );
          navigate("/signup/complete-registration/success");
        }
      })
      .catch((error) => {
        showNotification(AvailableNotificationTypes.Error, error.message);
      });
  };

  return {
    activateUser,
    loading: result.fetching,
  };
};

const INVITE_USERS = gql`
  mutation InviteUsers($input: UsersInviteInput!) {
    inviteUsers(input: $input) {
      uid
      email
    }
  }
`;

export const useInviteUsers = () => {
  const showNotification = useAppStore((state) => state.showNotification);
  const [result, callInviteUsers] = useMutation(INVITE_USERS);

  const inviteUsers = (variables: Record<string, any>) => {
    return callInviteUsers(variables)
      .then(({ data, error }) => {
        if (data?.inviteUsers) {
          showNotification(
            AvailableNotificationTypes.Success,
            "Invitation email sent",
          );
        }
        if (error) {
          throw new Error(error.message);
        }
      })
      .catch((error) => {
        showNotification(AvailableNotificationTypes.Error, error.message);
      });
  };

  return {
    inviteUsers,
    loading: result.fetching,
  };
};

const DELETE_USERS = gql`
  mutation DeleteUsers($ids: [ID!]!) {
    deleteUsers(ids: $ids) {
      id: _id
      isDeleted
    }
  }
`;

const DELETE_CONTACTS = gql`
  mutation DeleteContacts($ids: [ID!]!) {
    deleteContacts(ids: $ids) {
      id: _id
      isDeleted
    }
  }
`;

const RE_INVITE_USERS = gql`
  mutation ReInviteUsers($input: UsersReInviteInput!) {
    reInviteUsers(input: $input) {
      uid
      email
    }
  }
`;

export const useDeleteUsers = () => {
  const [result, callDeleteUsers] = useMutation(DELETE_USERS);
  const clearSelectedUsers = useUsersStore((state) => state.clearSelectedUsers);

  const deleteUsers = async (variables: Record<string, any>) => {
    return callDeleteUsers(variables).then(({ data }) => {
      if (data?.deleteUsers) {
        clearSelectedUsers();
      }
    });
  };

  return {
    deleteUsers,
    loading: result.fetching,
  };
};

export const useDeleteContacts = () => {
  const [result, callDeleteContacts] = useMutation(DELETE_CONTACTS);
  const clearSelectedUsers = useUsersStore((state) => state.clearSelectedUsers);

  const deleteContacts = async (variables: Record<string, any>) => {
    return callDeleteContacts(variables).then(({ data }) => {
      if (data?.deleteContacts) {
        clearSelectedUsers();
      }
    });
  };

  return {
    deleteContacts,
    loading: result.fetching,
  };
};

export const useReInviteUsers = () => {
  const showNotification = useAppStore((state) => state.showNotification);
  const [result, callReInviteUsers] = useMutation(RE_INVITE_USERS);

  const reInviteUsers = (variables: Record<string, any>) => {
    return callReInviteUsers(variables)
      .then(({ data }) => {
        if (data?.reInviteUsers) {
          showNotification(
            AvailableNotificationTypes.Success,
            "Invitation email sent",
          );
        }
      })
      .catch((error) => {
        showNotification(AvailableNotificationTypes.Error, error.message);
      });
  };

  return {
    reInviteUsers,
    loading: result.fetching,
  };
};
